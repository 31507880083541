/**
 * Initializes the Flatpickr plugin for date and time picker.
 *
 * @return {void}
 */
export function initializeFlatpickr() {
    const flatpickrDivs = document.querySelectorAll('[id=flatpickr]') // TODO: This needs to be a class instead of an id
    const timeZone = document.getElementById('time_zone')

    if (flatpickrDivs.length > 0) {
        loadFlatpickr().then((flatpickr) => {
            flatpickrDivs.forEach(flatpickDiv => {
                initializeFlatpickrDiv(flatpickDiv, flatpickr, timeZone)
            })
        }).catch((error) => {
            console.error('Error loading flatpickr:', error);
        });
    }
}

/**
 * Initializes the Flatpickr plugin for a specific div.
 *
 * @param {HTMLElement} flatpickrContainer - The div element to initialize Flatpickr on.
 * @param {Object} flatpickr - The Flatpickr library.
 * @param {HTMLElement} timeZone - The timezone element.
 *
 * @return {void}
 */
function initializeFlatpickrDiv(flatpickrContainer, flatpickr, timeZone) {
    const { defaultDate, minDate, maxDate } = getDateSettings(flatpickrContainer, timeZone)

    initializeDatePickers(flatpickrContainer, flatpickr, defaultDate, minDate, maxDate)
    initializeTimePickers(flatpickrContainer, flatpickr, defaultDate)
    initializeDateTimePickers(flatpickrContainer)

    flatpickrContainer.setAttribute('autocomplete', 'off')
}

/**
 * Gets the date settings (default, min, max) for a Flatpickr div.
 *
 * @param {HTMLElement} flatpickrContainer - The div element to get date settings for.
 * @param {HTMLElement} timeZone - The timezone element.
 *
 * @return {Object} - An object containing defaultDate, minDate, and maxDate.
 */
function getDateSettings(flatpickrContainer, timeZone) {
    let defaultDate = null
    if (flatpickrContainer.dataset.defaultdate?.length > 0) defaultDate = new Date(flatpickrContainer.dataset.defaultdate)
    if (timeZone && defaultDate) defaultDate = changeTimeZone(defaultDate, `Australia/${timeZone.innerText}`)

    let minDate = null
    console.debug('data:', flatpickrContainer.dataset);
    if (flatpickrContainer.dataset.mindate) { // TODO: Needs to be resolved to min-date instead of mindate
        console.debug('minDate:', flatpickrContainer.dataset.mindate);
        minDate = new Date().fp_incr(flatpickrContainer.dataset.mindate)
    }
    if (flatpickrContainer.dataset.mindate == 0) minDate = new Date()

    if (flatpickrContainer.dataset.startDate) {
        minDate = new Date(flatpickrContainer.dataset.startDate)
    }

    let maxDate = null
    if (flatpickrContainer.dataset.maxdate) maxDate = new Date(flatpickrContainer.dataset.maxdate)

    return { defaultDate, minDate, maxDate }
}

/**
 * Initializes the date pickers within a Flatpickr div.
 *
 * @param {HTMLElement} flatpickrContainer - The div element to initialize date pickers on.
 * @param {Object} flatpickr - The Flatpickr library.
 * @param {Date|null} defaultDate - The default date.
 * @param {Date|null} minDate - The minimum date.
 * @param {Date|null} maxDate - The maximum date.
 *
 * @return {void}
 */
function initializeDatePickers(flatpickrContainer, flatpickr, defaultDate, minDate, maxDate) {
    flatpickrContainer.querySelectorAll("[data-behavior='flatpickr-date']").forEach(datePickr => {
        flatpickr(datePickr, {
            altFormat: "D d F Y",
            dateFormat: "D d F Y",
            minDate: minDate,
            defaultDate: defaultDate,
            maxDate: maxDate,
            allowInput: true
        })
    })
}

/**
 * Initializes the time pickers within a Flatpickr div.
 *
 * @param {HTMLElement} flatpickrContainer - The div element to initialize time pickers on.
 * @param {Object} flatpickr - The Flatpickr library.
 * @param {Date|null} defaultDate - The default date.
 *
 * @return {void}
 */
function initializeTimePickers(flatpickrContainer, flatpickr, defaultDate) {
    flatpickrContainer.querySelectorAll("[data-behavior='flatpickr-time']").forEach(timePickr => {
        flatpickr(timePickr, {
            enableTime: true,
            noCalendar: true,
            dateFormat: "h:i K",
            defaultDate: defaultDate,
            allowInput: true
        })
    })
}

/**
 * Initializes the datetime pickers within a Flatpickr div.
 *
 * @param {HTMLElement} flatpickrContainer - The div element to initialize datetime pickers on.
 *
 * @return {void}
 */
function initializeDateTimePickers(flatpickrContainer) {
    flatpickrContainer.querySelectorAll("[data-behavior='flatpickr-datetime']").forEach(datetimePickr => {
        initializeFlatpickrDateTime(datetimePickr)
    })
}

/**
 * Initializes the Flatpickr plugin for datetime picker.
 *
 * @param {HTMLElement} datetimePickr - The HTML element to initialize the datetime picker on.
 *
 * @return {void}
 */
export function initializeFlatpickrDateTime(datetimePickr) {
    let defaultDate = findDefaultDate(datetimePickr)
    let localMinDate = findMinDateTime(datetimePickr)

    loadFlatpickr().then((flatpickr) => {
        flatpickr(datetimePickr, {
            enableTime: true,
            dateFormat: "D d M y, h:i K", // "%a %d %b %y, %I:%M %p"
            defaultDate: defaultDate,
            minDate: localMinDate,
            minuteIncrement: 5,
            defaultMinute: 0,
            allowInput: true
        })
    })
}

/**
 * Loads the Flatpickr library asynchronously and returns a promise that resolves to the Flatpickr module.
 *
 * @returns {Promise<unknown>} - A promise that resolves to the Flatpickr module.
 */
async function loadFlatpickr() {
    return new Promise(async (resolve, reject) => {
        try {
            const {default: flatpickr} = await import("flatpickr");
            resolve(flatpickr)
        } catch (error) {
            reject(error.message)
        }
    })
}

/**
 * Finds the minimum date and time for the datetime picker.
 *
 * @param {HTMLElement} datetimePickr - The HTML element to find the minimum date and time for.
 *
 * @return {Date|null} - The minimum date and time, or null if not set.
 */
function findMinDateTime(datetimePickr) {
    if (datetimePickr.dataset.minDateTime) {
        console.debug('minDateTime:', datetimePickr.dataset.minDateTime);
        return new Date(datetimePickr.dataset.minDateTime);
    }
    return null;
}

/**
 * Finds the default date for the datetime picker.
 *
 * @param {HTMLElement} datetimePickr - The HTML element to find the default date for.
 *
 * @return {Date|null} - The default date, or null if not set.
 */
function findDefaultDate(datetimePickr) {
    if (datetimePickr.dataset.defaultDate) {
        return new Date().fp_incr(datetimePickr.dataset.defaultDate)
    }
    return null;
}

/**
 * Changes the timezone of a given date.
 *
 * @param {Date|string} date - The date to change the timezone for.
 * @param {string} timeZone - The timezone to change the date to.
 *
 * @return {Date} - The date with the changed timezone.
 */
function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            }),
        );
    }

    return new Date(
        date.toLocaleString('en-US', {
            timeZone,
        }),
    );
}