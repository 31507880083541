import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core' // imports calendar
import dayGridPlugin from '@fullcalendar/daygrid' // allows daygrid
import timeGridPlugin from '@fullcalendar/timegrid'// allows timegrid
import interactionPlugin from '@fullcalendar/interaction' // allows interactions
import tippy from "tippy.js"
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

export default class extends Controller {
  static targets = [ "calendar", 'provider' ];

  initialize() {

     this.calendarUrl = this.element.getAttribute("data-calendar-url")
     this.trainingUrl = this.element.getAttribute("data-training-url")
  }

  async loadFullCalendar() {
    try {
      const { Calendar } = await import('@fullcalendar/core');
      const {default: dayGridPlugin} = await import('@fullcalendar/daygrid');
      const {default:timeGridPlugin} = await import('@fullcalendar/timegrid');
      const {default:interactionPlugin} = await import('@fullcalendar/interaction');

      return { Calendar, dayGridPlugin, timeGridPlugin, interactionPlugin };
    } catch (error) {
      console.error('Error loading FullCalendar modules:', error);
    }
  }

  eventSources(calendarUrl, trainingUrl) {
    if(trainingUrl?.length > 0 && this.hasProviderTarget) {
      // let provider_id = this.hasProviderTarget
      return  [
        {
          url: calendarUrl,
          method: 'GET',
          extraParams: {
            fullcalendar_request: 'true',
            request_type: 'appointments',
            provider_id: this.providerTarget.value
          }
        },
        {
          url: calendarUrl,
          method: 'GET',
          extraParams: {
            fullcalendar_request: 'true',
            request_type: 'trainings',
            provider_id: this.providerTarget.value
          }
        }
      ]
    } else {
      return  [
        {
          url: calendarUrl,
          method: 'GET',
          extraParams: {
            fullcalendar_request: 'true',
            request_type: 'appointments'
          }
        }
      ]
    }

  }

  connect() {
    this.loadFullCalendar().then(({ Calendar, dayGridPlugin, timeGridPlugin, interactionPlugin }) => {
      var defaultView = 'dayGridMonth';

      let calendarEl = this.calendarTarget;
      let hasProviderTarget = this.hasProviderTarget
      let calendar = new Calendar(calendarEl, {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        editable: true,
        initialView: defaultView,
        themeSystem: 'bootstrap5',
        slotEventOverlap: true,
        displayEventTime: false,
        expandRows: true,
        firstDay: 1,
        firstHour: 6, //doesn't work, should start the timetable view at 6HR
        slotMinTime: 6, //doesn't work, should start the timetable view at 6HR
        scrollTime :  "08:00:00", //scroll to 6am
        height: 600,
        contentHeight: 600,
        // aspectRatio: 2,
        nowIndicator: true, //show time indicator
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        eventSources: this.eventSources(this.calendarUrl, this.trainingUrl),
        businessHours: false,
        eventClick: function(info) {
          window.location.pathname = info.event.extendedProps.path;
        },
        eventMouseEnter: function(info) {
          tippy(info.el, {
            content: info.event.title + '\n' + '\n' + info.event.extendedProps.location,
            placement: 'top',
            animation: 'scale',
          })
        },
        dateClick: function(info) {
          if(hasProviderTarget) {
            info.dayEl.style.backgroundColor = '#d5f4ef';
            window.location.href = `new?date=${info.dateStr}`
          }

        },
        views: {
          dayGrid: {
            // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
          },
          timeGrid: {
            titleFormat: { year: 'numeric', month: 'long', day: '2-digit' },
            dayHeaderFormat: { day: '2-digit', weekday: 'short' },
            allDaySlot: false
          },
          week: {
            titleFormat: { year: 'numeric', month: 'short', day: '2-digit' },
            dayHeaderFormat: { year: 'numeric', month: 'short', day: '2-digit' }
          },
          day: {
            // options apply to dayGridDay and timeGridDay views
          }
        }
      });
      calendar.render();

    })
        .catch((e) => {
            console.error('There was an error loading FullCalendar', e);
        })

  }

}
